import { mapGetter } from 'CommonUtils/store/state.js';
import operations from "CommonUtils/operations/uniqBy";

export default function useBadgeText(props, labelProp) {
  const moduleName = mapGetter('filter/getActiveFilterModuleName').value;
  const { badgePromotions, promosPerProduct  } = toRefs(props);
  const nShowPromotions = computed(() => customization?.value?.promos_per_product || promosPerProduct?.value ||  1);
  
  let customization;
  let giftsWithStock;
  
  if (moduleName === 'search-filters') {
    customization = mapGetter('search-filters/getLayoutOptions');
    giftsWithStock = mapGetter('search-filters/getGiftsWithStock');
  } else {
    customization = mapGetter('page/getLayoutOptions');
    giftsWithStock = mapGetter('page/getGiftsWithStock');
  }

  const filteredSortedPromos = computed(() => {
    const promos = badgePromotions.value;

    if (!Array.isArray(promos)) {
      return [];
    }
    const filteredPromos = promos.filter(promo => {
      const hasRequiredProps = promo[labelProp] && promo.priority;
      const hasGiftProductsOutOfStock =
        promo.target_references?.length &&
        !promo.target_references.some((reference) => {
          const referenceId = reference.includes(':') ? reference.split(':')[0] : reference;
          return giftsWithStock.value.includes(referenceId);
        });

        return hasRequiredProps && !hasGiftProductsOutOfStock;
    });
  
    const sortedPromos = filteredPromos.sort((promoA, promoB) => promoA.priority - promoB.priority);
    const uniquePromos = operations.uniqBy(sortedPromos, labelProp);
  
    return uniquePromos;
  });
  const hasSeveralPrincipalPromos = computed(() => {
    return filteredSortedPromos.value?.length > nShowPromotions.value;
  });

  const promotionsDisplayed = computed(() => {
    return filteredSortedPromos.value?.slice(0, nShowPromotions.value);
  });

  return {
    hasSeveralPrincipalPromos,
    promotionsDisplayed,
  };
}
