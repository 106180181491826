<template>
  <span 
    v-if="isOpenInModal" 
    class="promo-badges__text pointer underline"
    :class="customClass"
    @click="openInModal">
    {{ label }}
  </span>

  <a
    v-else
    :href="promoBadgeUrl"
    :target="target">
    <span 
      class="promo-badges__text"
      :class="customClass"> 
      {{ label }}
    </span>
  </a>
</template>

<script>
 import useBadgeLink from 'Composables/promotions/useBadgeLink';

  export default {
    props: {
      customClass: {
        type: String,
        required: false,
        default: ''
      },
      label: {
        type: String,
        required: true,
      },
      promoBadgeUrl: {
        type: String,
        required: true,
      },
      windowType: {
        type: String,
        required: false,
        default: '3',
      },
    },
    emits: ['promoSelected'],
    setup(props, { emit }) {

      const { isOpenInModal, openInModal, target } = useBadgeLink(props, emit);
      
      return {
        isOpenInModal,
        openInModal,
        target,
      };
    },
  };
</script>