<template>
  <div
    v-for="(promotion) in filteredBadgePromotions"
    :key="promotion.id"
    class="promo-badges">
    <div
      v-if="promotion.promo_url_badge_text && !isIaChat">
      <PromoBadgeLink
        :label="promotion[labelProp]"
        :promoBadgeUrl="promotion.promo_url_badge_text"
        :windowType="getWindowType(promotion)"
        @promoSelected="setPromotionIframeAsVisible" /> 
    </div>
    <span 
      v-else 
      class="promo-badges__text">{{ promotion[labelProp] }}</span>
  </div>

  <ModalWithIframe
    v-if="showPromotionIframe"
    :iframeURL="iframeUrl"
    @closeModal="closeModal" />
</template>

<script>
  import PromoBadgeLink from './PromoBadgeLink.vue';
  export default {
    components: { 
      PromoBadgeLink,
      ModalWithIframe: defineAsyncComponent(() => import('CommonComponents/Iframe/ModalWithIframe.vue')), 
    },
    props: {
      badgePromotions: {
        type: Array,
        default: () => [],
        required: true,
      },
      isIaChat: {
        type: Boolean,
        default: false,
        required: false,
      },
      labelProp: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const iframeUrl = ref('');
      const showPromotionIframe = ref(false);

      const filteredBadgePromotions = computed(() => {
        return props.badgePromotions.filter(promotion => promotion[props.labelProp]);
      });

      function closeModal() {
        showPromotionIframe.value = false;
      }

      function setPromotionIframeAsVisible(iframeURL) {
        iframeUrl.value = iframeURL;
        showPromotionIframe.value = true;
      }

      function getWindowType(promotion) {
        return promotion?.promo_url_badge_text_windows_type ? promotion.promo_url_badge_text_windows_type : null;
      }

      return {
        iframeUrl,
        filteredBadgePromotions,
        showPromotionIframe,
        closeModal,
        setPromotionIframeAsVisible,
        getWindowType
      };
    },
  };
</script>